<template>
  <div>
    <box-section>Content</box-section>
    <row>
      <column-input>
        <form-group-select name="offers" :model="formData.offers" :options="data.offers" :disabled="!data.offers.length" :multiple="isBasic"/>
      </column-input>
    </row>
    <row>
      <column-input>
        <form-group-select name="templates_category" :model="formData.templates_category" :options="data.templateCats" :disabled="!data.templateCats.length" required/>
      </column-input>
      <column-input>
        <form-group-select name="templates" :model="formData.templates" :options="data.templates" :disabled="!data.templates.length" :multiple="isBasic" required/>
      </column-input>
      <column-input>
        <form-group-input name="first_name_default" :model="formData.first_name_default" :min="2"/>
      </column-input>
    </row>
  </div>
</template>

<script>
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupSelect from '@/views/components/form/FormGroupSelect'

export default {
  name: 'CampaignFormTypeContent',
  components: {
    FormGroupInput,
    FormGroupSelect
  },
  props: {
    data: { type: Object, required: true },
    formData: { type: Object, required: true },
    isBasic: { type: Boolean, required: true }
  }
}
</script>
