<template>
  <row>
    <column-input>
      <form-group-input name="name" :model="formData.name" :min="3" required/>
    </column-input>
    <column-input>
      <form-group-select name="type" title="Type" :model="formData.type" :options="data.types"
                         :disabled="isEdit || !data.types.length" required/>
    </column-input>
    <column-input>
      <form-group-select name="labels" :model="formData.labels" :options="data.labels" :disabled="!data.labels.length" multiple/>
    </column-input>
  </row>
</template>

<script>
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import FormGroupInput from '@/views/components/form/FormGroupInput'

export default {
  name: 'CampaignFormGeneral',
  components: {
    FormGroupSelect,
    FormGroupInput
  },
  props: {
    data: { type: Object, required: true },
    formData: { type: Object, required: true }
  },
  computed: {
    isEdit () { return this.$route.name === 'campaigns-edit' }
  }
}
</script>
