<template>
  <date-picker v-bind="$props" v-model="model.value"/>
</template>

<script>
import DatePicker from 'vue2-datepicker'

export default {
  name: 'FormDate',
  components: {
    DatePicker
  },
  props: {
    ...DatePicker.props,
    model: { type: Object, default: undefined },
    inputClass: { type: String, default: 'form-control' },
    required: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss">
$namespace: 'mx';
$default-color: #333;
$primary-color: #605ca8;
@import '~vue2-datepicker/scss/index.scss';

.mx-datepicker {
  display: block;
  width: auto;
}
.mx-table th {
  text-align: center;
}
.mx-table-date .today {
  font-weight: bold;
  &:not(.active) {
    border-radius: 5px;
    box-shadow: inset 0 0 0 1px #706cb1;
  }
}
.mx-time-column .mx-time-item.active {
  color: #fff;
  background-color: $primary-color;
}
</style>
