import Vue from 'vue'

const PageData = {
  result: {},
  loadLabels: function () {
    return Vue.axios
      .get('/campaigns/labels', { params: { no_pages: 1, active: 1 } })
      .then((res) => {
        const { data: { data } } = res
        this.result.labels = data.map((label) => { return { id: label.id, name: label.name } })
      })
  },
  loadLists: function () {
    return Vue.axios
      .get('/contact-lists/available')
      .then((res) => {
        const { data: { data } } = res
        this.result.lists = data.map((list) => { return { id: list.id, name: list.name } })
      })
  },
  loadApis: function (campaignType) {
    return Vue.axios
      .get('/apis/available', { params: { campaign_type: campaignType } })
      .then((res) => {
        const { data: { data } } = res
        this.result.apis = data.map((api) => { return { id: api.id, name: api.name } })
      })
  },
  loadSenderCats: function () {
    return Vue.axios
      .get('/senders/categories/available')
      .then((res) => {
        const { data: { data } } = res
        this.result.sender_categories = data.map((category) => { return { id: category.id, name: category.name } })
      })
  },
  loadDomains: function () {
    return Vue.axios
      .get('/domains/available')
      .then((res) => {
        const { data: { data } } = res
        this.result.domains = data.map((domain) => { return { id: domain.id, name: `${domain.name} [${domain.ip.ip}]` } })
      })
  },
  loadOffers: function () {
    return Vue.axios
      .get('/offers', { params: { no_pages: 1, active: 1 } })
      .then((res) => {
        const { data: { data } } = res
        this.result.offers = data.map((offer) => { return { id: offer.id, name: offer.name } })
      })
  },
  loadTemplateCats: function () {
    return Vue.axios
      .get('/templates/categories/available')
      .then((res) => {
        const { data: { data } } = res
        this.result.templateCats = data.map((cat) => { return { id: cat.id, name: cat.name } })
      })
  },
  loadTemplates: function (catID) {
    return Vue.axios
      .get('/templates', { params: { no_pages: 1, active: 1, category_id: catID } })
      .then((res) => {
        const { data: { data } } = res
        this.result.templates = data.map((template) => { return { id: template.id, name: template.name } })
      })
  },
  loadTypes: function () {
    return Vue.axios
      .get('/campaigns/types')
      .then((res) => {
        const { data: { data } } = res
        this.result.types = data.map((type) => {
          return { id: type.id, name: type.name }
        })
      })
  },
  loadAll: function () {
    return Promise.all([
      this.loadLabels(),
      this.loadLists(),
      this.loadSenderCats(),
      this.loadDomains(),
      this.loadOffers(),
      this.loadTemplateCats(),
      this.loadTypes()
    ])
  },
  setResult: function (result) {
    this.result = result
    return this
  }
}

export default PageData
