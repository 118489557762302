<template>
  <div>
    <box-section>Sending</box-section>
    <row>
      <column-input>
        <form-group-check name="starts_now" :model="formData.starts_now"/>
      </column-input>
      <column-input>
        <template v-if="!formData.starts_now.value">
          <form-group-date name="starts_at" :model="formData.starts_at" type="datetime" value-type="format" format="YYYY-MM-DD HH:mm" :minute-step="5" single time required/>
        </template>
        <div class="form-group required" v-else>
          <label for="starts_at_now">Starts At</label>
          <input type="text" value="NOW" id="starts_at_now" class="form-control" disabled/>
        </div>
      </column-input>
    </row>
    <row>
      <column-input>
        <form-group-select name="apis" :model="formData.apis" :options="data.apis" :disabled="!data.apis.length" :multiple="isBasic" required/>
      </column-input>
      <column-input>
        <form-group-select name="sender_categories" :model="formData.sender_categories" :options="data.sender_categories" :disabled="!data.sender_categories.length" multiple required/>
      </column-input>
      <column-input v-if="isBasic">
        <form-group-select name="domains" :model="formData.domains" :options="data.domains" :disabled="!data.domains.length || data.domainsDisabled " multiple required/>
      </column-input>
    </row>
  </div>
</template>

<script>
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupDate from '@/views/components/form/FormGroupDate'
import FormGroupSelect from '@/views/components/form/FormGroupSelect'

export default {
  name: 'CampaignFormSending',
  components: {
    FormGroupCheck,
    FormGroupDate,
    FormGroupSelect
  },
  props: {
    data: { type: Object, required: true },
    formData: { type: Object, required: true },
    isBasic: { type: Boolean, required: true }
  }
}
</script>
