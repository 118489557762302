<template>
  <div class="form-group" :class="{ 'required': required, 'has-error': errors.length }">
    <label :for="name">{{ titleValue }}</label>
    <div class="additional-info" v-if="$slots['info']">
      <slot name="info"/>
    </div>
    <form-date v-bind="$props" :placeholder="placeholderValue" :input-attr="inputAttrValue"/>
    <p class="help-block info" v-if="$slots['help']">
      <slot name="help"/>
    </p>
    <p class="help-block error" v-for="(error, i) in errors" :key="`${name}-error-${i}`">{{ error }}</p>
  </div>
</template>

<script>
import FormDate from '@/views/components/form/FormDate'

export default {
  name: 'FormGroupDate',
  components: {
    FormDate
  },
  props: {
    ...FormDate.props,
    name: { type: String, required: true },
    title: { type: String, default: '' }
  },
  computed: {
    errors () { return this.model.errors },
    titleValue () { return this.title ? this.title : this.$helpers.Str.titleCase(this.name) },
    placeholderValue () { return this.placeholder || this.titleValue },
    inputAttrValue () { return { id: this.name, name: this.name } }
  }
}
</script>
