<template>
  <div>
    <box-section>Recipients</box-section>
    <row>
      <column-input>
        <form-group-select name="lists" :model="formData.lists" :options="data.lists" :disabled="!data.lists.length" multiple required/>
      </column-input>
      <column-input :md="2" :xs="6">
        <form-group-check name="openers_only" :model="formData.openers_only"/>
      </column-input>
      <column-input :md="2" :xs="6">
        <form-group-check name="clickers_only" :model="formData.clickers_only"/>
      </column-input>
    </row>
  </div>
</template>

<script>
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupSelect from '@/views/components/form/FormGroupSelect'

export default {
  name: 'CampaignFormRecipients',
  components: {
    FormGroupCheck,
    FormGroupSelect
  },
  props: {
    data: { type: Object, required: true },
    formData: { type: Object, required: true },
    isBasic: { type: Boolean, required: true }
  }
}
</script>
